
.responsive-carousel {
    width: 100%;
    height: auto;
    overflow: hidden;
}

/* Responsive */

@media (max-width: 830px) {
    .services-container {
        display: none;
    }

    .resp-svcs-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        display: block;
    }

    .resp-svcs-square {
        position: relative;
        width: 100%;
        padding-top: 50%;
        object-fit: cover;
    } 

    .resp-svcs-img {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        transition: transform .2s; /* Animation */
    }

    .resp-svcs-img:hover {
        transform: scale(1.05);
    }

    .tag-resp {
        position: absolute;
        display: flex;
        background-color: #7c8988;
        color: #fffffe;
        z-index: 10;
        font-size: 4vw;
        padding: 7px;
        border-radius: 10px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        cursor: pointer;
    }
}