/* Universal Styles */
* {
    font-family: 'Trebuchet MS', sans-serif;
    
}

html, body {
    background-color: #f8f3f2;
    height: 100%;
    
}

a {
    text-decoration: none;
    color: #102f34;
}



/* main {
    background-image: url(../Images/v915-wit-011-d.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
} */

/* Header.js */

.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    color: #223d3c;
    min-height: 50px;
    background-color: #f8f3f2;
}

.logo {
    position: absolute;
    padding: 20px 0 10px 10px;
    z-index: 0;
    width: 200px;
    height: auto;
    top: -5px;
}

.logo-responsive {
    display: none;
}

.account {
    position: relative;
    float: right;
    right: 0px;
    top: 0px;
    padding-bottom: 10px;
    width: 350px; 
    display: flex; 
    align-items: center; 
    flex-wrap: nowrap; 
    column-gap: 10px;
    justify-content: flex-end;
}

.maindata {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
    column-gap: 10px;
    align-items: center; 
}

.maindata div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 10px;
    align-items: center; 
}

/* Navbar.js */

.navbar {
    background-color: #223d3c;
    padding: 5px 10px 10px 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
}

.navList a, .navList span {
    padding: 7px;
    color: #fffffe;
    text-decoration: none;
    background-color: #223d3c;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.navIcon {
    display: none;
}

/* About */

.top_page {    
    position: relative;
    color: #102f34;
    padding-left: 140px;
    padding-right: 140px;
}

.top_page h1 {
    background-color: #102f34;
    padding: 40px;
    color: #fffffe;
}

.top_page ul {
    text-align: justify;
    padding-right: 30px;    
}

/* Real Estate */

.realestate_btn {
    background-color: #102f34;
    border: none;
    color: #fffffe;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px;

}

/* Footer.js */

.footer {
    background-color: #223d3c;
    color: #fffffe;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 5vh;
    bottom: 0;
    font-size: large;
    border-top: 1px solid #f8f3f2;
}

/* ContactUs.js */

.contactus-container {
    padding: 50px 70px;
    display: block;
    text-align: center;
}

a.mui-icon {
    transition: transform 250ms;
    display: inline-block;
    
  }

.mui-icon {
    position: relative;
    margin: 0;
    margin-bottom: -5px;
}

  .mui-icon:hover {
    transform: translateY(-2px);
  }

.contact-h2 {
    background-color: #223d3c;
    color: #fffffe;
    margin: auto;
    min-width: 150px; 
    max-width: 300px;
    font-size: 25px;
    font-weight: bolder;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.contact-box {
    margin-bottom: 50px;
}

table {
    border-collapse: collapse;
}

table, td, th {
    border: 1px solid rgb(177, 177, 177);
    padding-left: 10px;
    padding-right: 10px;
}

/* Responsive */

@media (max-width: 830px) {

    /* Header */

    .maindata {
        display: none;
        background-color: #f8f3f2;
    }

    .logo {
        display: none;
    }

    .logo-responsive {
        display: flex;
        width: 100%;
        justify-content: center;
        background-color: #f8f3f2;
    }

    /* Navbar */

    .navList {
        display: none;
    }

    .navIcon {
        display: flex;
        justify-content: center;
        align-items: center; 
        width: 20px;
        height: 30px;
    }

    .top_page {
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .top_page p {
        padding-left: 5px;
        padding-right: 5px;
    }
}

