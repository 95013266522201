
/* Global Styles */

body {
    font-size: 100%;
    font-family: 'Trebuchet MS', sans-serif;
}

.wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    min-width: 220px;
    padding: 25px;
    margin: 80px 40px;
    font-size: 140%;
    font-weight: 600;
    color: #fffffe;
    background-color: #223d3c;
    border-radius: 10px;
}

.wrapper2 {
    display: flex;
    width: 100%;
    justify-content: center;
}

.btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    min-width: 50px;
    padding: 15px;
    margin: 50px 10px;
    font-size: 120%;
    font-weight: 600;
    color: #fffffe;
    background-color: #223d3c;
    border-radius: 10px;
    cursor: pointer;
}

.img_container {
    height: auto;
    margin-bottom: 50px;
}

.houseBox {
    position: relative;
    cursor: pointer;
    height: 100%;
    font-size: 1.1rem;
    font-family: sans-serif;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  */
}

@media (max-width: 1000px) {

    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .btn {
        max-width: 220px;
        margin: 10px 0px;
    }
}