/* GENERAL */

* {
    font-family: 'Trebuchet MS', sans-serif;
    line-height: 2rem;   
}

.flex-container {
    /* background-color: yellow; */
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
    border: solid 1px black;
    display: flex;
    justify-content: space-between;
}

.imgdiv {
    position: relative;
    height: 100%;
}

.txtdiv {
    position: relative;
    height: auto;
}

/* LawBtns */

.law_btns_page {
    background-color: darkorchid;
    position: relative;
}

.law_btns_links {
    display: flex;
    position: relative;
    width: 33.33%;
    height: 150px;
    float: left;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.law_btns_links div {
    position: relative;
    background-color: #223d3c;
    color: #fffffe;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    border-radius: 25px;
    width: 200px;
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media (max-width: 990px) {

    .law_btns_links {
        width: 50%;
    }
}

@media (max-width: 500px) {

    .law_btns_links {
        width: 100%;
    }
}